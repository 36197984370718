<template>
  <v-sheet
    min-height="70"
    :class="['d-flex white py-3 px-3', { 'justify-center': isMobile }, { 'py-5': fullManageIsApprove }]"
  >
    <div v-if="!fullManageIsApprove" class="d-flex flex-column flex-sm-row align-center flex-grow-1 justify-lg-space-between">
      <div class="d-flex align-center justify-center justify-lg-space-between ">
        <c-info-btn is-tooltip>
          <template #tooltipText>
            {{ $t('dashboard.full_manage.tooltip') }}
          </template>
        </c-info-btn>
        <div class="ml-2 text-subtitle-2 font-weight-regular mr-sm-4">
          {{ $t('dashboard.full_manage.label') }}
        </div>
      </div>
      <c-btn
        v-if="fullManageRequestable"
        x-large
        color="primary"
        :block="isMobile"
        class="mt-3 mt-sm-0"
        :label="$t('dashboard.full_manage.leave_a_request')"
        :loading="isLoading"
        @click="requestFullManage()"
      />
      <c-btn
        v-else-if="fullManageIsPending"
        outlined
        x-large
        color="warning"
        :block="isMobile"
        class="mt-3 mt-sm-0"
      >
        <v-icon size="18" color="warning">
          $clock
        </v-icon>
        <div class="black--text ml-3">
          {{ $t('dashboard.full_manage.pending') }}
        </div>
      </c-btn>
    </div>
    <div v-else class="d-flex justify-center align-center text-subtitle-2 font-weight-regular border-primary rounded mx-auto px-3">
      <span class="mr-1">{{ $t('dashboard.full_manage.label') }}:</span>
      <span class="primary--text">{{ $t('dashboard.full_manage.status') }}</span>
    </div>
  </v-sheet>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import personalRepository from '@/services/repositories/personal-repository.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'FullManageCard',
    components: { CBtn, CInfoBtn },
    data() {
      return {
        isLoading: false
      }
    },
    computed: {
      ...mapState('personal', ['fullManageStatus', 'actions']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      fullManageRequestable() {
        return this.actions.fullManageRequestable
      },
      fullManageIsApprove() {
        return this.fullManageStatus === 'approved'
      },
      fullManageIsPending() {
        return this.fullManageStatus === 'pending'
      }
    },
    created() {
      this.fetchUserProfile()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async requestFullManage() {
        this.isLoading = true
        try {
          await personalRepository.requestFullManage()
          await this.fetchUserProfile()
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>
