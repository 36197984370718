<template>
  <div class="subscribe-container rounded pa-5">
    <close-button class="mt-n4 mr-n3" right absolute @click="$emit('close')" />
    <div class="d-flex flex-column flex-sm-row justify-center align-center">
      <v-icon size="27" color="white" class="info rounded-pill pa-1">
        $telegram
      </v-icon>
      <div class="mx-3 py-3 py-sm-0">
        {{ $t('dashboard.telegram_banner', { name: adNetwork.name }) }}
      </div>
      <subscribe-telegram :gtm-event="GTM_EVENTS.TELEGRAM_SUBSCRIBE_BANNER" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import SubscribeTelegram from '@/components/SubscribeTelegram.vue'
  import CloseButton from '@/components/CloseButton.vue'

  export default {
    name: 'SubscribeTelegramBanner',
    components: { CloseButton, SubscribeTelegram },
    data() {
      return {
        GTM_EVENTS
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork'])
    }
  }
</script>

<style lang="scss" scoped>
  .subscribe-container {
    border: 1px solid var(--v-cpm-goal-color-base);
    position: relative;
  }
</style>
