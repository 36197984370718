<template>
  <v-card elevation="0">
    <v-card-title class="font-weight-bold secondary">
      {{ $t('dashboard.cost') }}
    </v-card-title>

    <v-card-text class="pb-0">
      <v-row>
        <v-col>
          <p class="mb-2">
            {{ $t('dashboard.today') }}:
          </p>
          <v-card-title class="pa-0 font-weight-bold primary--text custom-title">
            {{ toFixedByDefault(cost.todayCost) }}
          </v-card-title>
        </v-col>
        <v-col>
          <p class="mb-2">
            {{ $t('dashboard.this_month') }}:
          </p>
          <v-card-title class="pa-0 font-weight-bold primary--text custom-title">
            {{ toFixedByDefault(cost.currentMonthCost) }}
          </v-card-title>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="align-center">
        <v-col cols="6" class="d-flex align-center justify-space-between flex-wrap">
          <p class="mb-0 mr-3">
            {{ $t('dashboard.yesterday') }}:
          </p>
          <div class="title font-weight-medium pr-xl-7">
            {{ toFixedByDefault(cost.yesterdayCost) }}
          </div>
        </v-col>

        <v-col cols="6" class="d-flex align-center justify-space-between flex-wrap">
          <p class="mb-0 mr-3">
            {{ $t('dashboard.last_month') }}:
          </p>
          <div class="title font-weight-medium pr-xl-7">
            {{ toFixedByDefault(cost.previousMonthCost) }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'

  export default {
    name: 'CostCard',
    computed: {
      ...mapState('dashboard', ['cost'])
    },
    methods: {
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .custom-title {
    font-size: 32px;
  }
</style>
