<template>
  <v-skeleton-loader :loading="isLoading" type="list-item-avatar-three-line">
    <v-card @mouseleave="autorun = true" @mouseover="autorun = false">
      <v-window v-model="slide" class="elevation-1" vertical>
        <v-window-item v-for="post in posts" :key="post.id">
          <news-item :post="post" />
        </v-window-item>
      </v-window>
      <news-actions :slide="slide" :posts-length="posts.length" @change-slide="slide = $event" />
    </v-card>
  </v-skeleton-loader>
</template>

<script>
  import NewsItem from './NewsItem.vue'
  import NewsActions from './NewsActions.vue'
  import newsRepository from '@/services/repositories/news-repository.js'

  export default {
    name: 'NewsCard',
    components: {
      NewsItem,
      NewsActions
    },
    data() {
      return {
        isLoading: false,
        posts: [],
        slide: 0,
        showArrows: true,
        autorun: true,
        intervalFunction: null,
        timeShowWindow: 7000
      }
    },
    watch: {
      autorun: {
        immediate: true,
        handler(newVal) {
          this.sliderManager(newVal)
        }
      }
    },
    created() {
      this.loadNews()
    },
    methods: {
      async loadNews() {
        this.isLoading = true
        this.posts = await newsRepository.list()
        this.isLoading = false
      },
      slideNews() {
        return setInterval(() => {
          this.slide += 1
          if (this.slide >= this.posts.length) {
            this.slide = 0
          }
        }, this.timeShowWindow)
      },
      sliderManager(flag) {
        if (flag) {
          this.intervalFunction = this.slideNews()
        } else {
          clearInterval(this.intervalFunction)
        }
      }
    }
  }
</script>
