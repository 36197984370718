<template>
  <v-card elevation="0">
    <v-card-text class="overflow-hidden">
      <statistics-chart v-if="!chartIsLoading" />
      <v-progress-linear
        v-show="chartIsLoading"
        indeterminate
        color="primary"
        size="19"
        width="2"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import StatisticsChart from './StatisticsChart.vue'

  export default {
    name: 'StatisticsCard',
    components: {
      StatisticsChart
    },
    computed: {
      ...mapState('dashboard', ['chartIsLoading'])
    }
  }
</script>
