<template>
  <v-card flat>
    <div class="d-flex flex-no-wrap">
      <v-avatar class="ma-3" size="130" width="200px" tile>
        <v-img :src="post.featured_image" contain />
      </v-avatar>
      <div>
        <v-card-title class="text-h5">
          {{ post.title }}
        </v-card-title>
        <v-card-text>
          {{ post.summary }}
        </v-card-text>
        <a :href="post.url" target="_blank">
          <c-btn text color="primary" :label="$t('dashboard.read')" />
        </a>
      </div>
    </div>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'NewsItem',
    components: {
      CBtn
    },
    props: {
      post: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
