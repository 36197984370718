<template>
  <div v-if="dashboardIsLoading" class="loading">
    <v-progress-circular :size="50" indeterminate color="primary" />
  </div>
  <div v-else>
    <ads-spot
      v-if="adNetwork.settings.adsSpots.dashboardFourth.type !== null"
      :spot="adNetwork.settings.adsSpots.dashboardFourth"
      name="dashboardFourth"
    />
    <welcome-screen v-if="campaigns.campaignsTotal === 0" />
    <v-container v-else fluid class="px-0">
      <information-cards class="my-4" />
      <v-row>
        <v-col class="pt-0 px-4" cols="12" md="5">
          <full-manage-card v-if="isBeta && !fullManageIsRejected" />
          <balance-card class="mt-4" />
          <cost-card v-if="!isMobile" class="mt-4" />
          <statistics-card
            v-if="!isMobile && !isTablet && adNetworkHasSectionCampaignStatistics"
            class="mt-4"
          />
        </v-col>

        <template v-if="adNetworkHasSectionCampaignStatistics">
          <v-col class="pa-0 px-4 pt-md-0 pl-md-0" cols="12" md="7">
            <campaigns-card />
            <statistic-table v-if="!isMobile" class="mt-4" />
          </v-col>

          <v-col v-if="isMobile" class="pa-0 px-4 pt-4" cols="12" md="5">
            <cost-card />
          </v-col>

          <v-col v-if="isMobile" class="pa-0 px-4 pt-4" cols="12" md="7">
            <statistic-table />
          </v-col>

          <v-col v-if="isMobile || isTablet" class="pa-0 px-4 pt-4" cols="12" md="5">
            <statistics-card />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import BalanceCard from './BalanceCard.vue'
  import CostCard from './CostCard.vue'
  import CampaignsCard from './CampaignsCard/Index.vue'
  import StatisticsCard from './StatisticsCard/Index.vue'
  import StatisticTable from './StatisticTable.vue'
  import InformationCards from '@/views/Dashboard/InformationCards/InformationCards.vue'
  import guardSections from '@/types/guard-sections.js'
  import WelcomeScreen from '@/views/Dashboard/WelcomeScreen/Index.vue'
  import AdsSpot from '@/components/adsSpot.vue'
  import FullManageCard from '@/views/Dashboard/FullManageCard.vue'

  export default {
    name: 'Dashboard',
    components: {
      FullManageCard,
      AdsSpot,
      WelcomeScreen,
      InformationCards,
      BalanceCard,
      CostCard,
      CampaignsCard,
      StatisticsCard,
      StatisticTable
    },
    computed: {
      ...mapState('dashboard', ['dashboardIsLoading', 'campaigns']),
      ...mapState('settings', ['adNetwork']),
      ...mapState('personal', ['fullManageStatus']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isTablet() {
        return this.$vuetify.breakpoint.smOnly
      },
      isBeta() {
        return window.localStorage.getItem('isBeta')
      },
      adNetworkHasSectionCampaignStatistics() {
        return this.adNetwork.guardSections.some(
          ({ name }) => name === guardSections.CAMPAIGN_STATISTICS
        )
      },
      fullManageIsRejected() {
        return this.fullManageStatus === 'rejected'
      }
    },

    created() {
      this.fetchDashboard()
      this.fetchUserProfile()
    },
    methods: {
      ...mapActions('dashboard', ['fetchDashboard']),
      ...mapActions('personal', ['fetchUserProfile'])
    }
  }
</script>

<style lang="scss" scoped>
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 64px);
  }
</style>
