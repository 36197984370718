<template>
  <c-btn
    color="primary"
    :loading="isLoading"
    outlined
    height="32"
    class="d-flex align-center"
    :label="$t('main.login_subscribe')"
    depressed
    @click="openTelegram()"
  />
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { personalRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'SubscribeTelegram',
    components: { CBtn },
    props: {
      gtmEvent: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        temporaryUrl: null,
        isLoading: false,
        timerId: null
      }
    },
    beforeDestroy() {
      clearTimeout(this.timerId)
    },
    methods: {
      async openTelegram() {
        gtmPush({ event: this.gtmEvent })
        if (!this.temporaryUrl) {
          await this.onTelegramAuth()
          this.timerId = setTimeout(() => { this.temporaryUrl = null }, 35000)
        }
        if (this.temporaryUrl) {
          window.open(this.temporaryUrl, '_blank')
          this.$emit('subscribe')
        }
      },
      async onTelegramAuth() {
        this.isLoading = true
        try {
          const response = await personalRepository.generateUrlTelegram()
          this.temporaryUrl = response.temporary_url
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>
