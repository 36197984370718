<template>
  <div>
    <one-step-page v-if="welcomeScreenTypeIsOneStep" />
    <introductory-page v-else />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import IntroductoryPage from '@/views/Dashboard/WelcomeScreen/IntroductoryPage/Index.vue'
  import OneStepPage from '@/views/Dashboard/WelcomeScreen/OneStepPage.vue'

  export default {
    name: 'WelcomeScreen',
    components: { OneStepPage, IntroductoryPage },
    computed: {
      ...mapState('settings', ['adNetwork']),
      welcomeScreenTypeIsOneStep() {
        return this.adNetwork.settings.welcomeScreen.type === 'one-step'
      }
    }
  }
</script>
