<template>
  <v-card-actions class="primary justify-space-between">
    <c-btn text :icon-props="{ icon: 'mdi-chevron-left' }" @click="previousPost()" />
    <v-item-group
      :value="slide"
      class="text-center d-none d-sm-flex"
      mandatory
      @change="$emit('change-slide', $event)"
    >
      <v-item v-for="n in postsLength" :key="`btn-${n}`" v-slot="{ active, toggle }">
        <c-btn
          :input-value="active"
          icon
          dark
          :icon-props="{ icon: 'mdi-record' }"
          @click="toggle"
        />
      </v-item>
    </v-item-group>
    <c-btn text dark :icon-props="{ icon: 'mdi-chevron-right' }" @click="nextPost()" />
  </v-card-actions>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'NewsActions',
    components: {
      CBtn
    },
    props: {
      slide: {
        type: Number,
        default: 0
      },
      postsLength: {
        type: Number,
        default: 0
      }
    },
    methods: {
      previousPost() {
        const showWindowIndex = this.slide === 0 ? this.postsLength - 1 : this.slide - 1
        this.$emit('change-slide', showWindowIndex)
      },
      nextPost() {
        const showWindowIndex = this.slide + 1 === this.postsLength ? 0 : this.slide + 1
        this.$emit('change-slide', showWindowIndex)
      }
    }
  }
</script>
