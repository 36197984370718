<template>
  <v-container fluid class="py-0 px-2">
    <subscribe-telegram-dialog
      v-if="!fetchIsLoading && subscribeTelegramIsShown && !telegramSubscribeDialogIsShown"
      :is-shown="!telegramSubscribeDialogIsShown"
      @close="closeDialog()"
    />
    <v-row v-else-if="!fetchIsLoading">
      <v-col v-if="telegramBannerIsShown" cols="12" :md="accountManager && liveChatIsIntercom ? 8 : 12">
        <subscribe-telegram-banner @close="closeBanner()" />
      </v-col>
      <template v-else>
        <v-col v-if="adNetwork.settings.adsSpots.dashboardFirst.type !== null" cols="12" md="4">
          <ads-spot
            :spot="adNetwork.settings.adsSpots.dashboardFirst"
            name="dashboardFirst"
          />
        </v-col>
        <v-col v-if="adNetwork.settings.adsSpots.dashboardSecond.type !== null" cols="12" md="4">
          <ads-spot
            :spot="adNetwork.settings.adsSpots.dashboardSecond"
            name="dashboardSecond"
          />
        </v-col>
        <v-col
          v-if="adNetwork.settings.dashboardGallery"
          cols="12"
          :md="accountManager ? '8' : '12'"
        >
          <news-card />
        </v-col>
      </template>
      <v-col v-if="accountManager && liveChatIsIntercom" cols="12" md="4">
        <personal-manager-card :account-manager="accountManager" />
      </v-col>
      <v-col v-else-if="!telegramBannerIsShown && adNetwork.settings.adsSpots.dashboardThird.type !== null" cols="12" md="4">
        <ads-spot
          :spot="adNetwork.settings.adsSpots.dashboardThird"
          name="dashboardThird"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Cookies from 'js-cookie'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import AdsSpot from '@/components/adsSpot.vue'
  import PersonalManagerCard from '@/views/Dashboard/InformationCards/PersonalManagerCard.vue'
  import NewsCard from '@/views/Dashboard/InformationCards/NewsCard/Index.vue'
  import SubscribeTelegramBanner from '@/views/Dashboard/InformationCards/SubscribeTelegramBanner.vue'
  import guardSections from '@/types/guard-sections.js'
  import SubscribeTelegramDialog from '@/views/Dashboard/InformationCards/SubscribeTelegramDialog.vue'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'InformationCards',
    components: {
      SubscribeTelegramDialog,
      SubscribeTelegramBanner,
      NewsCard,
      PersonalManagerCard,
      AdsSpot
    },
    props: {
      telegramBanner: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        telegramSubscribeDialogIsShown: !!Number(Cookies.get('telegram_subscribe_dialog_is_shown')),
        telegramBannerViews: Number(localStorage.telegramBannerViews ?? 0),
        telegramBannerIsView: this.telegramBanner
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapState('personal', ['subscribeTelegram', 'fetchIsLoading']),
      accountManager() {
        return this.$auth.user?.account_manager
      },
      liveChatIsIntercom() {
        return this.adNetwork.settings.liveChat.type === 'intercom'
      },
      adNetworkHasSectionProfile() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.PROFILE)
      },
      subscribeTelegramIsShown() {
        return !this.subscribeTelegram
          && this.adNetworkHasSectionProfile
          && this.adNetwork.telegramBotName
      },
      telegramBannerIsShown() {
        return this.telegramBannerIsView && this.subscribeTelegramIsShown && this.telegramBannerViews % 4 === 0
      }
    },
    async created() {
      await this.fetchUserProfile()
      if (this.subscribeTelegramIsShown) {
        localStorage.telegramBannerViews = this.telegramBannerViews + 1
      }
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      closeDialog() {
        Cookies.set('telegram_subscribe_dialog_is_shown', 1, { expires: 30 })
        this.telegramSubscribeDialogIsShown = true
      },
      closeBanner() {
        gtmPush({ event: GTM_EVENTS.TELEGRAM_CLOSE_BANNER })
        this.telegramBannerIsView = false
      }
    }
  }
</script>
