<template>
  <v-card elevation="0">
    <v-data-table
      :loading="tableIsLoading"
      :headers="headers"
      :items="tableStatistics"
      :items-per-page="options.itemsPerPage"
      :page="options.currentPage"
      class="table-container"
      hide-default-footer
    >
      <template #item.campaign="{ item }">
        <router-link :to="{ name: 'Campaign', params: { id: item.campaign_id } }">
          {{ item.campaign }}
        </router-link>
      </template>

      <template #item.cost="{ item }">
        {{ toFixedByDefault(Number(item.cost)) }}
      </template>

      <template #footer>
        <data-table-footer
          :items-per-page="options.itemsPerPage"
          :current-page="options.currentPage"
          :page-count="pageCount"
          @change-items-per-page="changeItemsPerPage($event)"
          @change-current-page="changeCurrentPage($event)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'

  export default {
    name: 'StatisticTable',
    components: {
      DataTableFooter
    },
    data() {
      return {
        options: {
          currentPage: 1,
          itemsPerPage: 15,
          pageCount: 0
        }
      }
    },
    computed: {
      ...mapState('dashboard', ['tableStatistics', 'tableIsLoading']),
      headers() {
        return [
          { text: 'ID', value: 'campaign_id', width: '65px' },
          {
            text: this.$t('dashboard.campaign'), align: 'left', value: 'campaign', width: '130px'
          },
          { text: this.$t('dashboard.impressions'), value: 'impressions', width: '120px' },
          { text: this.$t('dashboard.clicks'), value: 'clicks', width: '100px' },
          { text: this.$t('dashboard.conversions'), value: 'conversions', width: '120px' },
          { text: this.$t('dashboard.cost'), value: 'cost', width: '120px' }
        ]
      },
      pageCount() {
        return Math.ceil(this.tableStatistics.length / this.options.itemsPerPage)
      }
    },
    methods: {
      getPageCount(event) {
        this.options.pageCount = event
      },
      changeCurrentPage(event) {
        this.options.currentPage = event
      },
      changeItemsPerPage(event) {
        this.options.itemsPerPage = event
      },
      toFixedByDefault
    }
  }
</script>
