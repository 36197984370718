<template>
  <v-card elevation="0" class="fill-height pa-1 border-primary">
    <v-layout class="fill-height" align-center>
      <v-avatar size="80px" class="ml-2">
        <v-img :src="accountManager.avatar_url" alt="avatar" />
      </v-avatar>

      <v-card-text class="pa-2">
        <div class="pl-2">
          {{ $t('dashboard.hello') }}
          {{ $t('dashboard.my_name_is', { name: accountManager.name }) }}
        </div>
        <c-btn
          text
          small
          class="text-decoration-underline px-2 primary--text"
          :label="$t('dashboard.contact_manager')"
          @click="$intercom.showNewMessage()"
        />
      </v-card-text>
    </v-layout>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'PersonalManagerCard',
    components: { CBtn },
    props: {
      accountManager: {
        type: Object,
        required: true
      }
    }
  }
</script>
