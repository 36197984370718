<template>
  <div>
    <custom-chart-series class="px-0 pb-2" :series="series" />

    <custom-chart :series="series" :options="options" type="line" height="400" width="100%" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import CustomChart from '@/components/CustomChart.vue'
  import CustomChartSeries from '@/views/Dashboard/StatisticsCard/CustomChartSeries.vue'

  export default {
    name: 'StatisticsChart',
    components: {
      CustomChart,
      CustomChartSeries
    },
    data() {
      return {
        defaultOptions: {
          stroke: {
            width: [0, 4],
            curve: 'smooth'
          },
          xaxis: {
            type: 'datetime'
          }
        }
      }
    },
    computed: {
      ...mapState('dashboard', ['typesChartStatistics', 'chartStatistics']),
      yaxis() {
        return [
          {
            title: {
              text: this.$t(`dashboard.statistics.${this.typesChartStatistics.columnType}`)
            }
          },
          {
            opposite: true,
            title: {
              text: this.$t(`dashboard.statistics.${this.typesChartStatistics.lineType}`)
            }
          }
        ]
      },
      columnSeries() {
        const { columnType } = this.typesChartStatistics
        const series = this.chartStatistics.map((item) => toFixedByDefault(item[columnType]))
        return {
          name: this.$t(`dashboard.statistics.${this.typesChartStatistics.columnType}`),
          type: 'column',
          data: series
        }
      },
      lineSeries() {
        const { lineType } = this.typesChartStatistics
        const series = this.chartStatistics.map((item) => toFixedByDefault(item[lineType]))
        return {
          name: this.$t(`dashboard.statistics.${this.typesChartStatistics.lineType}`),
          type: 'line',
          data: series
        }
      },
      labels() {
        if (this.chartStatistics[0]) {
          return this.chartStatistics.map((item) => item.date)
        }
        return []
      },
      series() {
        return [this.columnSeries, this.lineSeries]
      },
      options() {
        return {
          ...this.defaultOptions,
          labels: this.labels,
          yaxis: this.yaxis
        }
      }
    }
  }
</script>
