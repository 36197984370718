var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"font-weight-bold secondary"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.balance'))+" "),_c('v-spacer'),(_vm.adNetworkHasSectionAddFunds)?_c('c-btn',{staticClass:"primary--text",attrs:{"color":"black","depressed":"","to":{ name: 'AddFunds' },"label":_vm.$t('dashboard.add_funds').toUpperCase(),"icon-props":{
        icon: '$plus',
        size: '16'
      }}}):_vm._e()],1),_c('v-card-text',{staticClass:"d-flex flex-wrap align-center justify-space-between pa-4"},[_c('v-card-title',{staticClass:"black--text font-weight-bold custom-title pa-0"},[_vm._v(" $"+_vm._s(_vm.toFixedByDefault(_vm.balance))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('c-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","href":_vm.helpUrls.balance_reserved,"target":"_blank","icon-props":{
              icon: 'mdi-help-circle',
              color: 'primary',
              small: 'small'
            }}},'c-btn',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.why_there_is_a_reservation')))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.reserved', { quantity: _vm.toFixedByDefault(_vm.reservedBalance) }))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }