<template>
  <div class="overflow-hidden rounded black">
    <div
      class="
      one-step mx-auto d-flex flex-column align-center py-8 px-6 py-sm-14 px-sm-13 px-md-7 py-lg-9 px-lg-16"
    >
      <v-img
        class="mb-9 mb-sm-16 px-lg-16"
        :max-height="imageHeight"
        :src="imageUrl"
        contain
      />
      <div class="primary-sprite" />
      <div class="relative d-md-flex flex-row-reverse align-center mb-5 mb-sm-16 mt-sm-3 mt-md-6 mt-lg-9 px-md-5 px-lg-16">
        <div class="white--text text-body-2 text-center text-md-left mb-sm-4 mb-md-0">
          {{ $t('main.best_way', { network: adNetwork.name }) }}
        </div>
        <div class="start-btn mx-auto mr-md-5 mr-lg-14">
          <c-btn
            v-if="isSmAndUp"
            class="text-subtitle-2 primary white--text"
            height="32"
            :label="$t('main.start_now')"
            depressed
            block
            @click="onStartBtnClick()"
          />
        </div>
      </div>
      <div class="video-frame mt-sm-3 mt-md-9 mt-lg-10 mb-3">
        <iframe
          :src="videoUrl"
          allow="accelerometer;autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
        />
      </div>
      <c-btn
        v-if="!isSmAndUp"
        class="text-body-2 primary white--text"
        :label="$t('main.start_now')"
        height="46"
        block
        depressed
        @click="onStartBtnClick()"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'OneStepPage',
    components: { CBtn },
    computed: {
      ...mapState('settings', ['adNetwork']),
      imageUrl() {
        return this.adNetwork.settings.welcomeScreen.imageUrl
      },
      videoUrl() {
        return this.adNetwork.settings.welcomeScreen.videoUrl
      },
      imageHeight() {
        return {
          xs: '250px',
          sm: '375px',
          md: '500px',
          lg: '674px',
          xl: '674px'
        }[this.$vuetify.breakpoint.name]
      },
      startBtnSize() {
        return this.isMdAndUp ? '188px' : '300px'
      },
      videoSize() {
        return {
          xs: '163px',
          sm: '298px',
          md: '298px',
          lg: '399px',
          xl: '399px'
        }[this.$vuetify.breakpoint.name]
      },
      isSmAndUp() {
        return this.$vuetify.breakpoint.smAndUp
      },
      isMdAndUp() {
        return this.$vuetify.breakpoint.mdAndUp
      }
    },
    methods: {
      onStartBtnClick() {
        this.$router.push({ name: routeNames.CREATE_CAMPAIGN })
      }
    }
  }
</script>

<style scoped lang="scss">
  .relative {
    position: relative;
  }
  .one-step {
    position: relative;
    max-width: 1000px;
    &:before {
      content: "";
      background-color: var(--v-white-base);
      border-radius: 50%;
      position: absolute;
      top: -211px;
      right: -178px;
      width: 303px;
      height: 303px;
      filter: blur(125px);
      @media screen and (min-width: 601px) {
        top: -173px;
      }
      @media screen and (min-width: 960px) {
        top: -120px;
        right: -66px;
      }
        @media screen and (min-width: 1440px) {
        filter: blur(150px);
      }
    }
  }
  .primary-sprite {
    position: absolute;
    width: 687px;
    height: 687px;
    background-color: var(--v-primary-base);
    border-radius: 50%;
    bottom: -316px;
    right: -477px;
    filter: blur(200px);
    @media screen and (min-width: 960px) {
      right: -850px;
      bottom: -1000px;
      width: 1577px;
      height: 1577px;
      filter: blur(224px);
    }
    @media screen and (min-width: 1264px) {
      right: -630px;
      bottom: -885px;
    }
  }
  .video-frame {
    position: relative;
    width: 100%;
    max-width: 816px;
    height: v-bind(videoSize);
  }
  .start-btn {
    width: v-bind(startBtnSize)
  }
</style>
