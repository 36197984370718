<template>
  <v-row>
    <v-col v-for="(item, index) in series" :key="index" class="pt-0" cols="12" sm="6">
      <v-card elevation="0" class="border-secondary" :color="getColorByType(item.type)">
        <v-card-title class="d-flex justify-space-between white--text custom-title pa-2">
          {{ item.name }}
        </v-card-title>

        <v-divider class="mx-2" />

        <v-card-text>
          <p class="font-weight-medium white--text custom-total mb-0">
            {{ total(item) }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'

  const types = {
    IMPRESSIONS: 'Impressions',
    CLICKS: 'Clicks'
  }

  export default {
    name: 'CustomChartSeries',
    props: {
      series: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      getColorByType() {
        return (type) => (type === 'column' ? 'primary' : 'black')
      }
    },
    methods: {
      toFixedByDefault,
      total(statistics) {
        const { name, data } = statistics
        const total = data.reduce((acc, value) => Number(acc) + Number(value), 0)
        if (name === types.IMPRESSIONS || name === types.CLICKS) {
          return total
        }

        return this.toFixedByDefault(total)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .theme--light .v-divider {
    border-color: var(--v-white-base);
    opacity: 50%;
  }
  .custom-total {
    font-size: 20px;
  }
  .custom-title {
    font-size: 15px;
  }
</style>
