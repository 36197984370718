<template>
  <v-card elevation="0">
    <v-card-title class="font-weight-bold secondary">
      {{ $t('dashboard.balance') }}
      <v-spacer />
      <c-btn
        v-if="adNetworkHasSectionAddFunds"
        color="black"
        class="primary--text"
        depressed
        :to="{ name: 'AddFunds' }"
        :label="$t('dashboard.add_funds').toUpperCase()"
        :icon-props="{
          icon: '$plus',
          size: '16'
        }"
      />
    </v-card-title>

    <v-card-text class="d-flex flex-wrap align-center justify-space-between pa-4">
      <v-card-title class="black--text font-weight-bold custom-title pa-0">
        ${{ toFixedByDefault(balance) }}
      </v-card-title>
      <div>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <c-btn
              v-bind="attrs"
              icon
              small
              :href="helpUrls.balance_reserved"
              target="_blank"
              :icon-props="{
                icon: 'mdi-help-circle',
                color: 'primary',
                small: 'small'
              }"
              v-on="on"
            />
          </template>
          <span>{{ $t('dashboard.why_there_is_a_reservation') }}</span>
        </v-tooltip>
        <span>
          {{ $t('dashboard.reserved', { quantity: toFixedByDefault(reservedBalance) }) }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import guardSections from '@/types/guard-sections.js'

  export default {
    name: 'BalanceCard',
    components: {
      CBtn
    },
    computed: {
      ...mapState('dashboard', ['balance', 'reservedBalance']),
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('settings', ['helpUrls']),
      adNetworkHasSectionAddFunds() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.ADD_FUNDS)
      }
    },
    methods: {
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .custom-title {
    font-size: 28px;
  }
</style>
