<template>
  <v-dialog :value="isShown" max-width="400" overlay-opacity="0.85" @input="$emit('close')">
    <v-card class="pb-4">
      <v-card-title class="d-block pb-5">
        <close-button right absolute @click="closeDialog()" />
        <div class="text-center">
          <v-icon size="60" color="info">
            $telegram
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text class="px-16 text-center">
        {{ $t('dashboard.telegram_banner', { name: adNetwork.name }) }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <subscribe-telegram :gtm-event="GTM_EVENTS.TELEGRAM_SUBSCRIBE_DIALOG" @subscribe="$emit('close')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import SubscribeTelegram from '@/components/SubscribeTelegram.vue'
  import CloseButton from '@/components/CloseButton.vue'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'SubscribeTelegramDialog',
    components: { CloseButton, SubscribeTelegram },
    props: {
      isShown: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        GTM_EVENTS
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork'])
    },
    beforeDestroy() {
      this.$emit('close')
    },
    methods: {
      closeDialog() {
        gtmPush({ event: GTM_EVENTS.TELEGRAM_CLOSE_DIALOG })
        this.$emit('close')
      }
    }
  }
</script>
