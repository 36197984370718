<template>
  <v-card :loading="dashboardIsLoading" elevation="0">
    <v-card-title class="black white--text">
      {{ $t('dashboard.campaigns') }}
    </v-card-title>

    <v-card-text class="pa-0">
      <statistics-chart-settings class="ma-4 mb-0" />
    </v-card-text>

    <v-row class="px-5 pb-2">
      <v-col v-for="(campaign, index) in campaignsHoverCard" :key="index" cols="12" sm="6" lg="3">
        <v-card elevation="0" class="border-secondary card-hover" :to="campaign.to">
          <v-card-text>
            <p class="text-center title-hover">
              {{ campaign.title }}
            </p>
            <p class="mb-0 text-h3 font-weight-medium text-center primary--text text-hover">
              {{ campaign.text }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import StatisticsChartSettings from '@/views/Dashboard/CampaignsCard/StatisticsChartSettings.vue'

  export default {
    name: 'CampaignsCard',
    components: {
      StatisticsChartSettings
    },
    computed: {
      ...mapState('dashboard', ['campaigns', 'dashboardIsLoading']),
      campaignsHoverCard() {
        return [
          {
            title: this.$t('dashboard.pending'),
            text: this.campaigns.pendingCampaignsCount,
            to: { name: 'Campaigns', query: { statusApproval: 'pending' } }
          },
          {
            title: this.$t('dashboard.active'),
            text: this.campaigns.activeCampaignsCount,
            to: { name: 'Campaigns', query: { status: 'active' } }
          },
          {
            title: this.$t('dashboard.paused'),
            text: this.campaigns.pausedCampaignCount,
            to: { name: 'Campaigns', query: { status: 'paused' } }
          },
          {
            title: this.$t('main.total'),
            text: this.campaigns.campaignsTotal,
            to: { name: 'Campaigns' }
          }
        ]
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .card-hover:hover {
    background-color: var(--v-primary-base);
    .text-hover {
      color: var(--v-white-base) !important;
    }
    .title-hover {
      color: var(--v-white-base);
    }
  }
</style>
