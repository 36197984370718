<template>
  <v-item-group mandatory>
    <v-row>
      <v-col v-for="(item, index) in itemsBlock" :key="index" cols="12" md="6" lg="4">
        <v-item v-slot="{ active, toggle }">
          <v-card
            flat
            hover
            :color="active ? 'secondary-lighten' : 'black'"
            :class="[
              'd-flex flex-column justify-space-around pa-7 pa-xl-9',
              active ? 'active' : 'no-active'
            ]"
            min-height="375"
            @click="toggle"
          >
            <v-icon size="125" :color="active ? 'black' : 'secondary-darken'">
              {{ item.icon }}
            </v-icon>

            <v-card-text :class="['pa-0', active ? 'black--text' : 'white--text']">
              <div class="text-h6 mb-2">
                {{ item.title }}
              </div>
              <div class="opacity-60">
                {{ item.description }}
              </div>
            </v-card-text>

            <v-card-actions class="pa-0 pt-2">
              <c-btn block class="primary" :label="item.button" :to="{ name: item.nameRoute }" />
            </v-card-actions>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'HowToStart',
    components: {
      CBtn
    },
    computed: {
      itemsBlock() {
        return [
          {
            title: this.$t('dashboard.step', { number: 1 }),
            description: this.$t('dashboard.intro_page_description_1'),
            icon: '$dollar',
            button: this.$t('dashboard.add_funds'),
            nameRoute: 'AddFunds'
          },
          {
            title: this.$t('dashboard.step', { number: 2 }),
            description: this.$t('dashboard.intro_page_description_2'),
            icon: '$microphone',
            button: this.$t('dashboard.create_ad'),
            nameRoute: 'Ads'
          },
          {
            title: this.$t('dashboard.step', { number: 3 }),
            description: this.$t('dashboard.intro_page_description_3'),
            icon: '$lightning',
            button: this.$t('main.create_campaign'),
            nameRoute: 'CreateCampaign'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .active {
    border: 3px solid var(--v-primary-base) !important;
  }
  .no-active {
    border: 3px solid transparent;
  }
</style>
