<template>
  <div>
    <div v-if="isShown" @click="spotHandler($event.target)">
      <ins v-if="spot.type === 'adServer'" :id="htmlId" class="aso-zone" :data-zone="spot.zone_id" />
      <div v-else-if="spot.type === 'directInjection'" v-html="spot.code" />
    </div>
    <c-dialog v-model="dialogIsShown" max-width="400" :title="$t('main.hide_forever')">
      <div class="text-center">
        {{ $t('main.sure') }}
      </div>
      <template #actions>
        <div>
          <c-btn color="primary" depressed :label="$t('main.yes')" @click="closeBanner()" />
          <c-btn
            color="secondary"
            depressed
            :label="$t('main.no')"
            class="ml-4 black--text"
            @click="dialogIsShown = false"
          />
        </div>
      </template>
    </c-dialog>
  </div>
</template>

<script>
  import CDialog from '@clickadilla/components/ui/CDialog.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'AdsSpot',
    components: { CBtn, CDialog },
    props: {
      spot: {
        type: Object,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        htmlId: '',
        isShown: true,
        dialogIsShown: false
      }
    },
    created() {
      if (!localStorage.disabledAdsSpots) {
        localStorage.disabledAdsSpots = JSON.stringify([])
      }
      const disabledAdsSpots = JSON.parse(localStorage.disabledAdsSpots)
      this.isShown = !disabledAdsSpots.includes(this.name)
    },
    async mounted() {
      if (this.isShown && this.spot.type === 'adServer') {
        this.htmlId = await this.$adServer.init()
        this.$adServer.loadAd(this.htmlId, this.spot.zone_id)
      }
    },
    methods: {
      spotHandler(eventTarget) {
        const actions = {
          close: this.openDialog
        }
        actions[eventTarget.getAttribute('data-action')]?.()
      },
      openDialog() {
        this.dialogIsShown = true
      },
      closeBanner() {
        this.dialogIsShown = false
        this.isShown = !this.isShown
        localStorage.disabledAdsSpots = JSON.stringify([
          ...JSON.parse(localStorage.disabledAdsSpots),
          this.name
        ])
      }
    }
  }
</script>
