<template>
  <v-row>
    <v-col cols="12" md="6" xl="3" class="px-1 mb-1 pt-0">
      <c-select
        v-model="columnType"
        :items="types"
        item-text="text"
        item-value="value"
        :label="$t('dashboard.parameters')"
        hide-details
        class="d-block"
      >
        <template #prepend-inner>
          <v-icon color="info">
            $chart-bar
          </v-icon>
        </template>
      </c-select>
    </v-col>
    <v-col cols="12" md="6" xl="3" class="px-1 mb-1 pt-0">
      <c-select
        v-model="lineType"
        :items="types"
        item-text="text"
        item-value="value"
        :label="$t('dashboard.parameters')"
        hide-details
      >
        <template #prepend-inner>
          <v-icon color="primary">
            $chart-line
          </v-icon>
        </template>
      </c-select>
    </v-col>
    <v-col cols="12" xl="3" class="px-1 pt-0">
      <date-picker-select v-model="selectDateRange" with-presets buttons />
    </v-col>
    <v-col class="d-flex align-end px-1 pt-0 pb-5" cols="12" xl="3">
      <c-btn
        block
        color="primary"
        depressed
        :to="{ name: routeNames.CREATE_CAMPAIGN }"
        :label="$t('main.create_campaign')"
        :icon-props="{
          icon: '$plus',
          size: '14'
        }"
      />
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'StatisticsChartSettings',
    components: {
      CBtn,
      CSelect,
      DatePickerSelect
    },
    data() {
      return {
        routeNames
      }
    },
    computed: {
      ...mapState('dashboard', ['dateRange', 'typesChartStatistics']),
      types() {
        return [
          { text: this.$t('dashboard.statistics.cost'), value: 'cost' },
          { text: this.$t('dashboard.statistics.impressions'), value: 'impressions' },
          { text: this.$t('dashboard.statistics.clicks'), value: 'clicks' },
          { text: this.$t('dashboard.statistics.video_views'), value: 'video_views' },
          { text: this.$t('dashboard.statistics.ctr'), value: 'ctr' }
        ]
      },
      selectDateRange: {
        get() {
          return this.dateRange
        },
        set(val) {
          this.setDateRange(val)
        }
      },
      columnType: {
        get() {
          return this.typesChartStatistics.columnType
        },
        set(val) {
          this.setColumnType(val)
        }
      },
      lineType: {
        get() {
          return this.typesChartStatistics.lineType
        },
        set(val) {
          this.setLineType(val)
        }
      }
    },
    created() {
      this.defaultDateRange()
    },
    methods: {
      ...mapActions('dashboard', ['setDateRange', 'setLineType', 'setColumnType']),
      defaultDateRange() {
        const end = moment().format('YYYY-MM-DD')
        const start = moment().add({ days: -14 }).format('YYYY-MM-DD')
        this.selectDateRange = { start, end }
      }
    }
  }
</script>
