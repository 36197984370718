<template>
  <div>
    <div v-if="articlesList.length" class="text-h6">
      {{ $t('dashboard.useful_articles') }}
    </div>
    <v-container fluid class="px-0">
      <v-row>
        <v-col v-for="(article, index) in articlesList" :key="index" cols="12" xl="4" md="6">
          <v-card
            outlined
            class="d-flex align-center information-question-block black--text opacity-60 pa-6"
            :href="article.url"
          >
            {{ article.title }}
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ArticlesList',
    computed: {
      ...mapGetters('settings', ['helpUrls']),
      articlesList() {
        return [
          {
            title: this.$t('dashboard.article_how_to_start_first_company'),
            url: this.helpUrls.start_first_company
          },
          {
            title: this.$t('dashboard.article_banner_placement_types'),
            url: this.helpUrls.banner_placement_types
          },
          {
            title: this.$t('dashboard.article_become_publisher'),
            url: this.helpUrls.become_publisher
          },
          {
            title: this.$t('dashboard.article_how_to_track_conversions'),
            url: this.helpUrls.track_conversions
          },
          {
            title: this.$t('dashboard.article_what_is_minimum_finds'),
            url: this.helpUrls.minimum_funds_required
          }
        ].filter(({ url }) => !!url)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .information-question-block {
    min-height: 100px;
    background: var(--v-secondary-base);
    border-color: var(--v-secondary-base) !important;
    transition: 0.4s;

    &:hover {
      cursor: pointer;
      color: var(--v-primary-base);
      background: var(--v-primary-lighten3);
    }
  }
</style>
