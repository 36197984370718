<template>
  <div class="max-width mx-auto">
    <div class="text-h4 text-sm-h3 mt-6">
      {{ $t('dashboard.welcome_to_name', { name: adNetwork.name }) }}
    </div>
    <div class="text-body-1 mt-4 mb-6">
      {{ $t('dashboard.name_is_the_best', { name: adNetwork.name }) }}
    </div>
    <div class="text-h6 mt-4">
      {{ $t('dashboard.where_to_start') }}
      <how-to-start />
    </div>

    <div v-if="urlForCurrentLanguage" class="text-h6 mt-4">
      {{ $t('dashboard.how_do_create_an_ad') }}
      <iframe
        :src="urlForCurrentLanguage"
        allow="accelerometer;autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        width="100%"
        height="315"
        frameborder="0"
        allowfullscreen
      />
    </div>

    <div v-if="blogIsShown" class="text-h6 mt-4">
      {{ $t('dashboard.blog_title') }}
      <information-cards :telegram-banner="false" />
    </div>

    <articles-list class="text-body-2 mt-4" />

    <div class="d-flex justify-center">
      <a
        v-for="(bottomLink, index) in bottomLinks"
        :key="index"
        :href="bottomLink.url"
        class="information-bottom-link mr-4"
        target="_blank"
      >
        {{ bottomLink.title }}
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import HowToStart from '@/views/Dashboard/WelcomeScreen/IntroductoryPage/HowToStart.vue'
  import ArticlesList from '@/views/Dashboard/WelcomeScreen/IntroductoryPage/ArticlesList.vue'
  import InformationCards from '@/views/Dashboard/InformationCards/InformationCards.vue'

  export default {
    name: 'IntroductoryPage',
    components: {
      InformationCards,
      ArticlesList,
      HowToStart
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('settings', ['helpVideosUrls', 'helpUrls']),
      bottomLinks() {
        return [
          {
            title: this.$t('dashboard.article_privacy_policy'),
            url: this.helpUrls.privacy_policy
          },
          {
            title: this.$t('dashboard.article_terms_and_conditions'),
            url: this.helpUrls.terms_and_conditions
          }
        ]
      },
      urlForCurrentLanguage() {
        return this.$i18n.locale === 'ru'
          ? this.helpVideosUrls.how_create_first_campaign_ru
          : this.helpVideosUrls.how_create_first_campaign_en
      },
      blogIsShown() {
        return this.adNetwork.settings.dashboardGallery
          || this.adNetwork.settings.adsSpots.dashboardFirst.zone_id
          || this.adNetwork.settings.adsSpots.dashboardSecond.zone_id
          || this.adNetwork.settings.adsSpots.dashboardThird.zone_id
      }
    }
  }
</script>

<style lang="scss" scoped>
  .max-width {
    max-width: 1000px;
  }
</style>
